import { useState, useRef, useEffect, MutableRefObject } from "react";

type useItemDescriptionReturnType = {
    open: boolean;
    setOpen: (val: boolean) => void;
    height: number;
    contentRef: MutableRefObject<HTMLSpanElement | null>;
};

const useItemDescription = (isPdf?: boolean): useItemDescriptionReturnType => {
    const [open, setOpen] = useState<boolean>(isPdf || false);
    const [height, setHeight] = useState<number>(0);
    const contentRef = useRef<HTMLSpanElement | null>(null);

    // TODO: decriptions with <p> tags are not height limited
    useEffect(() => {
        if (contentRef.current) {
            const elementHeight = contentRef.current.getBoundingClientRect().height;
            setHeight(elementHeight);
        }
    }, []);

    return {
        open,
        setOpen: (val: boolean) => setOpen(val),
        height,
        contentRef,
    };
};

export default useItemDescription;
