import React from "react";
import styled from "styled-components";
import useMaxNumberToCompare from "../../../../shared-logic/features/compare/hooks/useMaxNumberToCompare";

// ----------------------------------------------------------------------
// Styles
// ----------------------------------------------------------------------

export const TableRow = styled.div`
    display: flex;
    flex-wrap: nowrap;
`;

export const TableCol = styled.div<{ visible: boolean; widthPercentage: number }>`
    display: ${(props) => (props.visible ? "flex" : "none")};
    width: ${(props) => props.widthPercentage * 100}%;
`;

// ----------------------------------------------------------------------
// Component
// ----------------------------------------------------------------------

type PropsType<T extends unknown> = {
    /** Each item corresponds to a column */
    items: T[];
    /** Component to render in the column */
    columnChild: (item: T, index: number) => React.ReactNode;
    /** Will be displayed once as the last column when not all places are filled */
    placeholder?: () => React.ReactNode;
};

/**
 * Provides a fixed table structure for all Compare V2 parts across all variants.
 * Currently limited to a single row (having more would require a more thorough rework of the entire compare).
 */
const CompareTable = <T extends unknown>(props: PropsType<T>): JSX.Element => {
    const { items, columnChild, placeholder } = props;
    const maxNumberToCompare = useMaxNumberToCompare();

    return (
        <TableRow role="row">
            {items.map((item, index) => {
                return (
                    <TableCol
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        visible={index < maxNumberToCompare} // hidden via css for performance when resizing
                        widthPercentage={1 / maxNumberToCompare}
                        role="cell"
                    >
                        {columnChild(item, index)}
                    </TableCol>
                );
            })}

            {items.length < maxNumberToCompare && placeholder && (
                <TableCol visible widthPercentage={1 / maxNumberToCompare} role="cell">
                    {placeholder()}
                </TableCol>
            )}
        </TableRow>
    );
};

export default CompareTable;
