import { UsedCarCompareActionsType, USED_CAR_COMPARE_SET_DATA } from "../actions/UsedCarCompareActions";
import { USED_CAR_COMPARE_CLEAR_ALL } from "../../../../common-deprecated/features/compare/redux/actions/UsedCarCompareExternalDataActions";

import { SpecItemType } from "../../../../common-deprecated/features/compare/utils/newCarCompareTypes";
import { UsedParsedEquipment } from "../../utils/compareTypes";

export type UsedCarCompareReducerType = {
    parsedSpecs: SpecItemType[];
    parsedEquipment: UsedParsedEquipment[];
};

export const initialUsedCarCompareState: UsedCarCompareReducerType = {
    parsedSpecs: [],
    parsedEquipment: [],
};

export default (
    state: UsedCarCompareReducerType = initialUsedCarCompareState,
    action: UsedCarCompareActionsType,
): UsedCarCompareReducerType => {
    switch (action.type) {
        case USED_CAR_COMPARE_CLEAR_ALL:
            return { ...initialUsedCarCompareState };
        case USED_CAR_COMPARE_SET_DATA:
            return {
                ...state,
                parsedSpecs: action.specs,
                parsedEquipment: action.equipment,
            };
        default:
            return state;
    }
};
