import { LabelComponentType, getLabelComponent } from "../../../common-deprecated/components/label/Label";
import {
    getUseLabel,
    UseLabelType,
    getUseOptionalLabel,
    UseOptionalLabelType,
    getLabelFn,
    GetLabelType,
    GetOptionalLabelType,
} from "../../../common-deprecated/hooks/useLabel";
import { CommonSettingsType } from "../../../common-deprecated/settings/fetchCommonSettings";
import { T1Labels } from "../../../common-deprecated/utils/t1labels";
import { CompareUscSettingsType } from "../redux/reducers/CompareUscSettingsReducer";
import { CompareUSCStateType } from "../redux/store";
import { UsedCompareSpec } from "./usedSpecsUtils";

// ----------------------------------------------------------------------
// Accessibility labels
// ----------------------------------------------------------------------
export const accessibilityLabels = {
    ariaRemoveFromCompare: "Remove from compare",
};

export const compareUSCLabels = {
    ...T1Labels,
    ...accessibilityLabels,

    /**
     * Used Car Compare
     */
    // ctas
    usedCompareButtonAppointment: "Book appointment",
    usedCompareContactDealerModalTitle: "Information request",
    usedCompareButtonSeeCar: "See car",
    // highlighted specs
    usedCompareSpecBodyType: "Body type",
    usedCompareSpecDoors: "Doors",
    usedCompareSpecEmission: "Combined Co2",
    usedCompareSpecEngine: "Engine",
    usedCompareSpecExteriourColour: "Colour",
    usedCompareSpecFuel: "Fuel type",
    usedCompareSpecFuelHybrid: "Hybrid",
    usedCompareSpecMileage: "Mileage",
    usedCompareSpecPowerOutput: "Power output",
    usedCompareSpecServiced: "Serviced", // not yet used
    usedCompareSpecTransmission: "Transmission",
    usedCompareSpecWarranty: "Warranty",
    usedCompareSpecYear: "Registration Year",
    usedCompareSpecWheelDrive: "Wheel Drive",
    usedCompareSpecDistance: "Distance",
    usedCompareSpecDistanceValue: "{value} km away",
    usedCompareSpecModelYear: "Model Year",
    usedCompareSpecVinNumber: "VIN Number",
    usedCompareSpecDisplayAuthorization: "Display Authorization",
    usedCompareSpecEngineNumber: "Engine Number",
    usedCompareSpecNextInspectionDate: "Next Inspection Date",
    usedCompareSpecIsPledgedSale: "Pledged Sale",
    usedCompareSpecEfficiencyClass: "Efficiency Class",

    // Yes/No boolean spec Values
    usedCompareSpecYes: "Yes",
    usedCompareSpecNo: "No",

    // price spec
    usedCompareSpecPrice: "{cash} or from",
    usedCompareSpecCashOnly: "From",
    usedCompareSpecMonthlyUnit: "/mo",
    // spec categories
    usedCompareCatDimension: "Dimensions and chassis",
    usedCompareCatConsumption: "Consumption",
    usedCompareCatEngine: "Engine",
    usedCompareCatPerformance: "Performance",
    usedCompareCatTransmission: "Transmission",
    // detail specs (reuses some from highlighted)
    usedCompareSpecSeats: "Number of seats",
    usedCompareSpecCarVolume: "Total capacity",
    usedCompareSpecConsumption: "Consumption",
    usedCompareSpecFuelEconomy: "Fuel economy",
    usedCompareSpecCylinders: "Cylinders",
    usedCompareSpecEngineCapacity: "Capacity",
    usedCompareSpecMaxSpeed: "Max speed",
    usedCompareSpecAcceleration: "Acceleration 0-100 km/h",
    usedCompareSpecGearbox: "Gearbox",
    usedCompareSpecFiscalePower: "Fiscale Power",
    // disclaimers
    usedComparePageDisclaimerTitle: "Disclaimers",
    usedComparePageDisclaimer: "",

    /**
     * New Car Compare labels used in the used car compare
     */
    // modal
    compareModalDeleteCar: "Are you sure you want to remove the {carToDelete} from the comparison?",
    compareModalRemoveButton: "Remove",
    compareModalCancelButton: "Cancel",
    compareModalReplaceCar:
        "You can only compare {maxNumberToCompare} cars. Which car do you want to replace by the {newCar} ?",
    compareSelectBack: "Back",
    compareBodyLoading: "loading",

    // header
    compareHeaderTitle: "Compare",
    compareHeaderHide: "Hide",
    compareHeaderClearAll: "Clear all",
    compareHeaderChooseUpToXCars: "Choose up to {maxNumberOfCars} cars",

    compareBodyCarSpecsTitle: "Car Specifications",
    compareBodyLegendStandard: "Standard",
    compareBodyEquipment: "Equipment",
    compareBodySaveAsPdf: "Save comparison as PDF",
    compareBodyLegendNotApplicable: "Not applicable",
};
export type CompareUSCLabelStateType = {
    commonSettings: CommonSettingsType;
    compareUscSettings: CompareUscSettingsType;
};

export type CompareUSCLabelType = keyof typeof compareUSCLabels;

export const usedSpecsLabelMap: Record<UsedCompareSpec, CompareUSCLabelType> = {
    [UsedCompareSpec.BodyType]: "usedCompareSpecBodyType",
    [UsedCompareSpec.Doors]: "usedCompareSpecDoors",
    [UsedCompareSpec.Emission]: "usedCompareSpecEmission",
    [UsedCompareSpec.Engine]: "usedCompareSpecEngine",
    [UsedCompareSpec.ExteriorColour]: "usedCompareSpecExteriourColour",
    [UsedCompareSpec.FuelType]: "usedCompareSpecFuel",
    [UsedCompareSpec.Mileage]: "usedCompareSpecMileage",
    [UsedCompareSpec.PowerOutput]: "usedCompareSpecPowerOutput",
    [UsedCompareSpec.RegistrationDate]: "usedCompareSpecYear",
    [UsedCompareSpec.Transmission]: "usedCompareSpecTransmission",
    [UsedCompareSpec.Warranty]: "usedCompareSpecWarranty",
    [UsedCompareSpec.Price]: "usedCompareSpecPrice",
    [UsedCompareSpec.WheelDrive]: "usedCompareSpecWheelDrive",
    [UsedCompareSpec.Distance]: "usedCompareSpecDistance",
    [UsedCompareSpec.ModelYear]: "usedCompareSpecModelYear",
    [UsedCompareSpec.VinNumber]: "usedCompareSpecVinNumber",
    [UsedCompareSpec.DisplayAuthorization]: "usedCompareSpecDisplayAuthorization",
    [UsedCompareSpec.EngineNumber]: "usedCompareSpecEngineNumber",
    [UsedCompareSpec.NextInspectionDate]: "usedCompareSpecNextInspectionDate",
    [UsedCompareSpec.IsPledgedSale]: "usedCompareSpecIsPledgedSale",
    [UsedCompareSpec.EfficiencyClass]: "usedCompareSpecEfficiencyClass",
};

type CompareUSCLabelParamType = Partial<
    Record<"value" | "cash" | "maxNumberOfCars" | "maxNumberToCompare" | "carToDelete" | "newCar", string | number>
>;

export const useCompareUSCLabel = getUseLabel(
    (state: CompareUSCStateType) => state.compareUscSettings.labels,
    (state: CompareUSCStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as UseLabelType<CompareUSCLabelType, CompareUSCLabelParamType>;
export const useCompareUSCOptionalLabel = getUseOptionalLabel(
    (state: CompareUSCStateType) => state.compareUscSettings.labels,
    (state: CompareUSCStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as UseOptionalLabelType<CompareUSCLabelType, CompareUSCLabelParamType>;
export const getCompareUSCLabel = getLabelFn(
    (state: CompareUSCStateType) => state.compareUscSettings.labels,
    (state: CompareUSCStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as GetLabelType<CompareUSCLabelStateType, CompareUSCLabelType, CompareUSCLabelParamType>;
export const getCompareUSCOptionalLabel = getLabelFn(
    (state: CompareUSCStateType) => state.compareUscSettings.labels,
    (state: CompareUSCStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as GetOptionalLabelType<CompareUSCLabelStateType, CompareUSCLabelType, CompareUSCLabelParamType>;
export const CompareUSCLabel = getLabelComponent(useCompareUSCLabel) as LabelComponentType<
    CompareUSCLabelType,
    CompareUSCLabelParamType
>;
