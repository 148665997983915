import { trackEvent } from "../../../common-deprecated/utils/tracking";

export const trackAemUsedCompareOpen = (carCount: number): void =>
    trackEvent({
        name: "componentevent",
        component: "used-cars-compare",
        group: "used-cars",
        action: "open-compare-panel",
        label: String(carCount),
    });

export const trackAemUsedCompareClose = (): void =>
    trackEvent({
        name: "componentevent",
        component: "used-cars-compare",
        group: "used-cars",
        action: "close-compare-panel",
    });

export const trackAemUsedCompareClearAll = (): void =>
    trackEvent({
        name: "componentevent",
        component: "used-cars-compare",
        group: "used-cars",
        action: "clear-all",
    });

export const trackAemUsedCompareRemoveVehicle = (trackedVehicleName: string): void =>
    trackEvent({
        name: "componentevent",
        component: "used-cars-compare",
        group: "used-cars",
        action: "remove-used-car",
        label: trackedVehicleName,
    });

export const trackAemUsedCompareCtaClick = (ctaName: string): void =>
    trackEvent({
        name: "ctaevent",
        component: "used-cars-compare",
        group: "used-cars",
        action: "click-cta",
        label: ctaName,
    });

export const trackAemUsedComparePdfClick = (): void =>
    trackEvent({
        name: "ctaevent",
        component: "used-cars-compare",
        group: "used-cars",
        action: "download-uc-comparison",
        label: "pdf",
    });

export const trackAemUsedCompareSectionExpand = (sectionName: string): void =>
    trackEvent({
        name: "componentevent",
        component: "used-cars-compare",
        group: "used-cars",
        action: "expand-specifications",
        label: sectionName,
    });
