import styled, { createGlobalStyle } from "styled-components";
import { theme } from "../../../styles/v2/toyota/theme/toyotaTheme";

export const SmoothScrollStyle = createGlobalStyle`
    :root, html {
        scroll-behavior: smooth;
    }
`;

export const Indicator = styled.div<{ show: boolean; bottomOffset?: number }>`
    display: flex;
    position: fixed;
    bottom: ${({ bottomOffset }) => (bottomOffset ? `${bottomOffset}px` : "0")};
    left: 50%;
    align-items: center;
    justify-content: center;
    width: 68px;
    height: 68px;
    margin-bottom: 32px;
    margin-left: -34px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px -2px 30px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s;
    cursor: ${({ show }) => (show ? "pointer" : "default")};
    pointer-events: ${({ show }) => (show ? "auto" : "none")};
    opacity: ${({ show }) => (show ? 1 : 0)};
    z-index: ${theme.zIndex.fixed};
`;

export const Gradient = styled.div<{ show: boolean; bottomOffset?: number }>`
    position: fixed;
    bottom: ${({ bottomOffset }) => (bottomOffset ? `${bottomOffset}px` : "0")};
    left: 0;
    right: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(180deg, rgba(108, 112, 115, 0) 0%, rgba(108, 112, 115, 0.2) 100%);
    pointer-events: none;
    transition: opacity 0.3s;
    opacity: ${({ show }) => (show ? 1 : 0)};
    z-index: ${theme.zIndex.fixed - 1}; /* Minus one so it renders under the static filter bar */
`;
