import React from "react";
import * as Modal from "../../../styles/v2/toyota/globals/Modal";
import * as Button from "../../../styles/v2/toyota/components/Button";
import * as Text from "../../../styles/v2/toyota/utilities/Text";
import * as ButtonGroup from "../../../styles/v2/toyota/components/ButtonGroup";
import ButtonPrimary from "../../aem/toyota/ButtonPrimary";
import { LineClamp } from "./css/ModalActionViewsStyles";

export type MultipleChoiceType = {
    list: {
        id: string;
        label?: string;
        handler?: (index: number) => void;
    }[];
    onCancel: () => void;
    cancelLabel: string;
};
export const MultipleChoice = (props: MultipleChoiceType): JSX.Element => {
    const { list, onCancel, cancelLabel } = props;
    return (
        <Modal.Actions>
            <ButtonGroup.Vertical>
                {list.map((actionItem, index) => {
                    return (
                        <ButtonPrimary
                            key={actionItem.id}
                            onClick={() => actionItem.handler && actionItem.handler(index)}
                            fullWidth
                        >
                            <LineClamp>{actionItem.label || ""}</LineClamp>
                        </ButtonPrimary>
                    );
                })}
                <Button.Outline width={1} m={0} onClick={onCancel}>
                    <Text.Button>{cancelLabel}</Text.Button>
                </Button.Outline>
            </ButtonGroup.Vertical>
        </Modal.Actions>
    );
};

export type OKCancelType = {
    onApprove: () => void;
    approveLabel: string;
    onCancel: () => void;
    cancelLabel: string;
};
export const OKCancel = (props: OKCancelType): JSX.Element => {
    const { onApprove, approveLabel, onCancel, cancelLabel } = props;
    return (
        <Modal.Actions>
            <ButtonGroup.Horizontal>
                <Button.Primary width={1} m={0} onClick={onApprove}>
                    <Text.Button>{approveLabel}</Text.Button>
                </Button.Primary>
                <Button.Outline width={1} m={0} onClick={onCancel}>
                    <Text.Button>{cancelLabel}</Text.Button>
                </Button.Outline>
            </ButtonGroup.Horizontal>
        </Modal.Actions>
    );
};
