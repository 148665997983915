import { createLogic } from "redux-logic";
import {
    USED_CAR_COMPARE_SET_CARS_TO_ADD,
    UsedCarCompareSetCarsToAddType,
    usedCompareSetCarsToAdd,
    usedCompareSetParsedData,
} from "../actions/UsedCarCompareActions";
import {
    usedCompareRemoveCars,
    UsedCarCompareRemoveCarsType,
    usedCompareSetParsedCars,
    UsedCarCompareSetParsedCarsType,
    USED_CAR_COMPARE_SET_PARSED_CARS,
    USED_CAR_COMPARE_REMOVE_CARS,
    UsedCarCompareClearAllType,
    USED_CAR_COMPARE_CLEAR_ALL,
    USED_CAR_COMPARE_ADD_CARS,
    UsedCarCompareAddCarsType,
} from "../../../../common-deprecated/features/compare/redux/actions/UsedCarCompareExternalDataActions";

import Debug from "../../../../common-deprecated/Debug";
import { getMaxNumberOfCarsToCompare } from "../../../../shared-logic/features/compare/utils/helpers";
import { saveUsedCarsToLocalStorage } from "../../utils/localStorage";
import getTheme from "../../../../common-deprecated/themes/getTheme";
import { showModal } from "../../../../common-deprecated/redux/actions/ModalActions";
import {
    UsedCarsCompareRequestBodyType,
    UsedCarsCompareRequestReturnType,
} from "../../../../common-deprecated/features/compare/utils/usedCarCompareTypes";
import { parseUsedCarSpecs, parseUsedCarEquipment } from "../../utils/formatters/usedSpecsFormatters";
import { getAPI } from "../../../../common-deprecated/settings/utils/commonSettingUtils";
import { MODAL_REPLACE_CAR } from "../../../../shared-logic/features/compare/utils/modalConstants";
import { CompareUSCLogicType } from "../store";
import { shouldShowErrorModal } from "../../../../common-deprecated/utils";
import { MODAL_ERRORS } from "../../../../common-deprecated/utils/modalConstants";
import { COMPONENT_COMPARE_USC } from "../../../../shared-logic/server/components";

const startComparisonLogic = (createLogic as CompareUSCLogicType<UsedCarCompareAddCarsType>)({
    type: [USED_CAR_COMPARE_ADD_CARS],

    validate({ getState, action }, allow, reject) {
        const { commonSettings, compareUscSettings, usedCarCompareExternalData } = getState();
        const { compareV2Standalone } = compareUscSettings;

        const newIndex = action.index === -1 ? usedCarCompareExternalData.cars.length : action.index;
        const { breakpoints } = getTheme(commonSettings);

        // check to see if the added car/cars aren't already present in the compare.
        const existingIds = usedCarCompareExternalData.cars.map((car) => car.id);
        // filter the ID's that are already in the compare.
        // This is done because the used car compare can add multiple cars at once.
        // This way it will only reject when no cars are left to be added.
        const filteredCars = action.cars.filter((sm) => !existingIds.includes(sm.id));

        // Only reject if the array of filtered id's is empty.
        if (!filteredCars.length) {
            // @ts-ignore
            reject();
            return;
        }

        if (newIndex + filteredCars.length <= getMaxNumberOfCarsToCompare(breakpoints, compareV2Standalone)) {
            allow({ ...action, cars: filteredCars, index: newIndex } as any);
        } else {
            reject(usedCompareSetCarsToAdd(filteredCars));
        }
    },
    process: async ({ getState, action }, dispatch, done): Promise<void> => {
        const { commonSettings, compareUscSettings } = getState();
        const { cars, index } = action;
        const ids = cars.map((car) => car.id);
        const dealerDistances = cars.map((car) => car.dealerDistance);

        const url = `${getAPI(commonSettings, "usedcars/compare")}?brand=${commonSettings.brand}${
            commonSettings.query.useMockData ? "&useMockData=true" : ""
        }`;

        try {
            const body: UsedCarsCompareRequestBodyType = {
                uscEnv: compareUscSettings.uscEnv,
                vehicleForSaleIds: ids,
            };
            const fetchResult = await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                referrer: "no-referrer",
                body: JSON.stringify(body),
            });

            const { carResults, errorLogs }: UsedCarsCompareRequestReturnType = await fetchResult.json();

            // Show errors if present.
            if (shouldShowErrorModal(commonSettings, errorLogs)) {
                const modalSettings = {
                    type: MODAL_ERRORS,
                    errors: errorLogs,
                    componentName: COMPONENT_COMPARE_USC,
                };
                dispatch(showModal(modalSettings));
            }

            if (!carResults || !carResults.length)
                throw new Error(`Returned data not valid: ${JSON.stringify(carResults)}`);
            if (carResults.length !== ids.length) {
                const indexToRemove = ids
                    .map((id, idIndex) => (!carResults.find((car) => car.id === id) ? idIndex : null))
                    .filter((value) => value !== null) as number[];
                dispatch(usedCompareRemoveCars(indexToRemove));
            }

            const resultsWithCorrectDistance = [
                ...carResults.map((car, carIndex) => ({
                    ...car,
                    dealer: car.dealer
                        ? { ...car.dealer, distance: parseInt(dealerDistances?.[carIndex] || "0") }
                        : car.dealer,
                })),
            ];

            dispatch(usedCompareSetParsedCars(resultsWithCorrectDistance, index));
        } catch (ex) {
            Debug.error("Error while executing used cars compare request", ex);
        }
        done();
    },
});

const setParsedCarLogic = (
    createLogic as CompareUSCLogicType<UsedCarCompareSetParsedCarsType | UsedCarCompareRemoveCarsType>
)({
    type: [USED_CAR_COMPARE_SET_PARSED_CARS, USED_CAR_COMPARE_REMOVE_CARS],
    process({ getState }, dispatch, done) {
        const { commonSettings, usedCarCompareExternalData } = getState();
        saveUsedCarsToLocalStorage(
            usedCarCompareExternalData.cars.map((car) => ({
                id: car.id,
                product: {
                    brand: car.product.brand,
                    model: car.product.model,
                },
                dealerDistance: !car.loading && car.dealer ? `${car.dealer.distance}` : undefined,
            })),
            commonSettings,
        );

        const parsedSpecs = parseUsedCarSpecs(usedCarCompareExternalData.cars, commonSettings.culture);
        const parsedEquipment = parseUsedCarEquipment(usedCarCompareExternalData.cars);

        dispatch(usedCompareSetParsedData(parsedSpecs, parsedEquipment));

        done();
    },
});

const replaceCarLogic = (createLogic as CompareUSCLogicType<UsedCarCompareSetCarsToAddType>)({
    type: [USED_CAR_COMPARE_SET_CARS_TO_ADD],
    validate({ getState, action }, allow, reject) {
        if (action.cars.length === 1) {
            allow(action);
        } else {
            // @ts-ignore
            reject();
        }
    },
    async process({ getState, action }, dispatch, done) {
        dispatch(
            showModal({
                type: MODAL_REPLACE_CAR,
                usedCarToAdd: action.cars[0],
            }),
        );
        done();
    },
});

const clearAllLogic = (createLogic as CompareUSCLogicType<UsedCarCompareClearAllType>)({
    type: [USED_CAR_COMPARE_CLEAR_ALL],
    process({ getState }) {
        const { commonSettings } = getState();
        saveUsedCarsToLocalStorage([], commonSettings);
    },
});

const usedCarCompareLogic = [startComparisonLogic, setParsedCarLogic, replaceCarLogic, clearAllLogic];
export default usedCarCompareLogic;
export const serverUsedCarCompareLogic = [startComparisonLogic, setParsedCarLogic]; // Needed for PDF Rendering
