import { getSpecValues, UsedCompareSpec, UsedParsedSpecType } from "../utils/usedSpecsUtils";
import { useCompareUSCSelector } from "../redux/store";
import { useCompareUSCLabel } from "../utils/labels";
import { LoadedUsedCompareCarType } from "../../../common-deprecated/features/compare/utils/usedCarCompareTypes";
import { showUscPrice } from "../../shared-logic/utils/uscPriceUtils";

/**
 * This hook returns the primary and secondary specs for the used cars.
 * It also returns the TTMS specs, which is only used for TTMS.
 * TODO: In the future, we should refactor this hook to make use of the Content Fragment system.
 * However, this requires a lot of work in AEM, which is why we're not doing it now.
 * Accepts isPdf attribute to conditionally hide certain specs if pdf
 */
const useUsedCarSpecs = (
    isPdf?: boolean,
): {
    primarySpecs: UsedParsedSpecType[];
    secondarySpecs: UsedParsedSpecType[];
    TTMSSpecs: UsedParsedSpecType[];
} => {
    const cars = useCompareUSCSelector((state) => state.usedCarCompareExternalData.cars);
    const settings = useCompareUSCSelector((state) => state.commonSettings);
    const [yesLabel, noLabel] = useCompareUSCLabel(["usedCompareSpecYes", "usedCompareSpecNo"]);

    // Hide the prices row when the showUscPrice check is false for all the cars
    const hidePrices = (cars as LoadedUsedCompareCarType[]).every(
        (car) => !showUscPrice(car.price.pricingSource?.code),
    );

    // TODO: this will become configurable in the future
    let primaryKeys = [
        UsedCompareSpec.Engine,
        UsedCompareSpec.Price,
        UsedCompareSpec.Mileage,
        UsedCompareSpec.RegistrationDate,
    ];
    // Filter out the price key when no cars should show a price
    if (hidePrices) primaryKeys = primaryKeys.filter((key) => key !== UsedCompareSpec.Price);

    const secondaryKeys = [
        UsedCompareSpec.Warranty,
        UsedCompareSpec.Emission,
        ...(!isPdf ? [UsedCompareSpec.EfficiencyClass] : []),
        UsedCompareSpec.FuelType,
        UsedCompareSpec.ExteriorColour,
        UsedCompareSpec.BodyType,
        UsedCompareSpec.Doors,
        UsedCompareSpec.Transmission,
        UsedCompareSpec.WheelDrive,
        UsedCompareSpec.PowerOutput,
        UsedCompareSpec.Distance,
    ];

    /**
     * These keys are only used for TTMS, and shown under the secondary specs.
     * If we ever need country-specific specs, we should refactor this to either:
     * - Use the Content Fragment system
     * - Let these be configurable in i18n (array of key names)
     */
    const TTMSKeys = [
        UsedCompareSpec.ModelYear,
        UsedCompareSpec.VinNumber,
        UsedCompareSpec.DisplayAuthorization,
        UsedCompareSpec.EngineNumber,
        UsedCompareSpec.NextInspectionDate,
        UsedCompareSpec.IsPledgedSale,
    ];

    /**
     * Returns three sets of specs:
     * - primarySpecs: First specs shown in the compare table, in bold
     * - secondarySpecs: Second specs shown in the compare table, in normal font
     * - TTMSSpecs: Specs shown only when the country is Turkey, under the secondary specs
     */
    return {
        primarySpecs: getSpecValues(primaryKeys, cars as LoadedUsedCompareCarType[], settings, {
            yes: yesLabel,
            no: noLabel,
        }),
        secondarySpecs: getSpecValues(secondaryKeys, cars as LoadedUsedCompareCarType[], settings, {
            yes: yesLabel,
            no: noLabel,
        }),
        TTMSSpecs: getSpecValues(TTMSKeys, cars as LoadedUsedCompareCarType[], settings, {
            yes: yesLabel,
            no: noLabel,
        }),
    };
};

export default useUsedCarSpecs;
