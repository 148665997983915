import { useState, useEffect } from "react";
import { hideSiteElements } from "../utils/helpers";
import { LEXUS } from "../../../../common-deprecated/constants";
import { useCommonSelector } from "../../../../common-deprecated/redux/commonStore";

type PropsType = {
    standalone?: boolean;
    topOfCompareRef: React.RefObject<HTMLElement>;
    bodyRef: React.RefObject<HTMLElement>;
    hasCars: boolean;
};

type ReturnType = {
    show: boolean;
    expanded: boolean;
    toggleExpanded: () => void;
    scrollToTop: () => void;
    animationEnded: boolean;
    toggleAnimationEnded: (val: boolean) => void;
};

const useCompareToggle = (props: PropsType): ReturnType => {
    const { topOfCompareRef, bodyRef, hasCars, standalone } = props;

    const commonSettings = useCommonSelector((state) => state.commonSettings);

    const isLexus = commonSettings.brand === LEXUS;

    const shouldAlreadyBeOpened = !!commonSettings.query.compareData || !!standalone;
    const [expanded, setExpanded] = useState<boolean>(shouldAlreadyBeOpened);
    const [animationEnded, setAnimationEnded] = useState(false);

    useEffect(() => {
        setAnimationEnded(false);
    }, [expanded]);

    useEffect(() => {
        if (shouldAlreadyBeOpened) {
            hideSiteElements(true, isLexus);
        }
    }, []);

    // We place an emtpy span element at the top of the compare to use when we click on the header
    // when scrolled down to scroll it into view. ScrollIntoView has the widest support (IE11).
    const scrollToTop = (): void => {
        if (bodyRef.current && topOfCompareRef.current) {
            topOfCompareRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
    };

    // Helper for pressing show/hide button
    const toggleExpanded = (): void => {
        hideSiteElements(!expanded, isLexus);
        setExpanded(!expanded);
    };

    // allows the compare pane to be closed by pressing the escape key
    useEffect(() => {
        if (expanded) {
            const toggleKey = (event: KeyboardEvent): void => {
                if (event.key.toLowerCase() === "escape") setExpanded(false);
            };

            window.addEventListener("keydown", toggleKey);
            return () => window.removeEventListener("keydown", toggleKey);
        }
    });

    const toggleAnimationEnded = (ended: boolean): void => {
        setAnimationEnded(ended);
    };

    // Cleanup when there are no cars left in the compare (remove all, or removed one by one)
    useEffect(() => {
        if (!hasCars && !standalone && !commonSettings.query.compareData) {
            setExpanded(false);
            hideSiteElements(false, isLexus);
        }
    }, [hasCars, standalone]);

    return {
        show: hasCars || standalone || false,
        expanded,
        toggleExpanded,
        scrollToTop,
        animationEnded,
        toggleAnimationEnded,
    };
};

export default useCompareToggle;
