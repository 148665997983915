import styled from "styled-components";

// ----------------------------------------------------------------------
// Styles
// ----------------------------------------------------------------------
// ❗ Only extend these with design specific styles via a wrapper as this component is used across all Compare variants

export const TableRow = styled.div`
    display: flex;
    flex-wrap: nowrap;
`;

export const TableCol = styled.div<{ visible: boolean; widthPercentage: number }>`
    display: ${(props) => (props.visible ? "flex" : "none")};
    width: ${(props) => props.widthPercentage * 100}%;
`;

export const InnerCol = styled.div`
    width: 100%;
`;
