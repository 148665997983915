export enum CompareV2Mode {
    New = "new",
    Used = "used",
}

export type CompareParams = {
    // Used in compare V2 to directly render component with data
    compareData?: string;
    // Used in compare V2 to directly render new/used/stock cars
    compareMode?: CompareV2Mode;
    // Used in eg compare V2 to render a pdf
    isPdf?: string;
    modelId?: string;
    carId?: string;
    onlyShowDifferencesCar?: boolean;
    onlyShowDifferencesEngine?: boolean;
    // Used to indicate if compare-V2 is used as a standalone applciation
    compareV2Standalone?: boolean;
};
