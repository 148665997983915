import { CompareUSCStateType } from "../redux/store";
import { COMPONENT_COMPARE_USC_PDF } from "../../../shared-logic/server/components";
import { DisclaimerLocation, DisclaimerType } from "../../../common-deprecated/types/CommonTypes";
import { LoadedUsedCompareCarType } from "../../../common-deprecated/features/compare/utils/usedCarCompareTypes";
import {
    getUscCashPriceFromVehicle,
    showUscInlinePriceDisclaimer,
    uscPriceIsExclVat,
    uscPriceIsInclVat,
} from "../../shared-logic/utils/uscPriceUtils";
import { getCommonOptionalLabel } from "../../../common-deprecated/utils/commonLabels";
import { getDisclaimerLinkLabel } from "../../shared-logic/utils/uscUtils";
import { AemFmComponent } from "../../../common-deprecated/utils/aemFlexibilityMatrixUtils";

export enum UsedCarCompareDisclaimerType {
    Cash = "cash",
    Finance = "finance",
}

/**
 * Returns a list of disclaimers that need to be referenced
 */
export const disclaimerSelector = (state: CompareUSCStateType): DisclaimerType<UsedCarCompareDisclaimerType>[] => {
    let disclaimers: DisclaimerType<UsedCarCompareDisclaimerType>[] = [];
    const { commonSettings, compareUscSettings, usedCarCompareExternalData } = state;
    const { country } = commonSettings;
    const { cars } = usedCarCompareExternalData;
    const showFootnote = (disclaimer: UsedCarCompareDisclaimerType): boolean =>
        !!compareUscSettings.usedFootnoteDisclaimers.find((footnoteDisclaimer) => footnoteDisclaimer === disclaimer);

    const isPdf = commonSettings.component === COMPONENT_COMPARE_USC_PDF;

    (cars.filter((car) => !car.loading) as LoadedUsedCompareCarType[]).forEach((car) => {
        // Cash
        const cashPrice = getUscCashPriceFromVehicle(car);
        const cashDisclaimer = uscPriceIsExclVat(cashPrice)
            ? getCommonOptionalLabel(state, "uscExclVatCashPriceDisclaimer")
            : uscPriceIsInclVat(cashPrice)
            ? getCommonOptionalLabel(state, "uscInclVatCashPriceDisclaimer")
            : getCommonOptionalLabel(state, "uscCashPriceDisclaimer");

        if (cashDisclaimer) {
            let location = DisclaimerLocation.InfoIcon;
            if (showFootnote(UsedCarCompareDisclaimerType.Cash)) location = DisclaimerLocation.Footnote;
            else if (showUscInlinePriceDisclaimer(country) && !car.monthlyPrice) location = DisclaimerLocation.Inline;

            disclaimers.push({
                type: UsedCarCompareDisclaimerType.Cash,
                reference: car.id,
                value: cashDisclaimer,
                location,
            });
        }

        // Finance
        if (car.financeInfo?.disclaimer) {
            const disclaimerLabel = getDisclaimerLinkLabel(
                commonSettings,
                AemFmComponent.Pdp,
                car.monthlyPrice,
                car.financeInfo,
                "used",
            );

            let location = DisclaimerLocation.InfoIcon;
            if (showFootnote(UsedCarCompareDisclaimerType.Cash)) location = DisclaimerLocation.Footnote;
            else if (showUscInlinePriceDisclaimer(country)) location = DisclaimerLocation.Inline;
            else if (disclaimerLabel) location = DisclaimerLocation.InfoText;

            disclaimers.push({
                type: UsedCarCompareDisclaimerType.Finance,
                reference: car.id,
                value: car.financeInfo?.disclaimer.value,
                containsHtml: true,
                location,
                infoText: disclaimerLabel || "",
            });
        }
    });

    // Force footnotes for PDF disclaimers.
    if (isPdf) {
        disclaimers = disclaimers.map((disclaimer) => ({
            ...disclaimer,
            // Dont override inline price disclaimers as these are rendered next to the price anyway.
            location:
                showUscInlinePriceDisclaimer(country) && disclaimer.location === DisclaimerLocation.Inline
                    ? DisclaimerLocation.Inline
                    : DisclaimerLocation.Footnote,
        }));
    }

    // Add a number only for the footnote items to avoid 'invisible' numbers.
    let latestIndex = 0;
    disclaimers.map((disclaimer) => {
        if (disclaimer.location === DisclaimerLocation.Footnote) {
            latestIndex++;
            disclaimer.number = latestIndex;
        }
        return disclaimer;
    });

    return disclaimers;
};

/**
 * Wrapper for the disclaimer selector, allows to easily retrieve a specific disclaimer config.
 */
export const getDisclaimerSelector =
    (type: UsedCarCompareDisclaimerType, reference?: string) =>
    (state: CompareUSCStateType): DisclaimerType<UsedCarCompareDisclaimerType> | null => {
        const disclaimers = disclaimerSelector(state);

        return disclaimers.find((disclaimer) => disclaimer.type === type && disclaimer.reference === reference) || null;
    };

/**
 * Get a disclaimer id for usage in HTML ids or React keys.
 */
export const getDisclaimerId = (disclaimer: DisclaimerType<UsedCarCompareDisclaimerType>): string =>
    `${disclaimer.type}-${String(disclaimer.number)}-${disclaimer.reference || ""}-${disclaimer.location}`;
