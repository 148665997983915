import { EquipmentSpecNameIdType } from "../../../../common-deprecated/features/compare/utils/newCarCompareTypes";

// Check if we should show the row (so return false if onlyShowDifferences is true, and all the values are the same)
export const showItemsForThisRow = (onlyShowDifferences: boolean, allValuesAreTheSame: boolean): boolean => {
    return !onlyShowDifferences || !allValuesAreTheSame;
};

// Check if we have to render the collapsible list. We do not want to render the title if it has no Items,
// nor if we have items, but they each row has all identical values and the showOnlyDifferences switch is true;
export const collapsibleListHasItemsToShow = (
    onlyShowDifferences: boolean,
    items: EquipmentSpecNameIdType[],
): boolean => {
    if (items.length === 0) return false;
    if (!onlyShowDifferences) return true;
    return !!items.find((item) => showItemsForThisRow(onlyShowDifferences, !!item.allValuesAreTheSame));
};
