import React from "react";
import { useCommonSelector } from "../../redux/commonStore";
import { EcoLabelPopupLabelType } from "../../types/SettingsType";
import * as Styles from "./styles/EmissionLabelStyles";

type PropsType = {
    label: EcoLabelPopupLabelType;
};

const EmissionLabelPopup = ({ label }: PropsType): JSX.Element | null => {
    const ecoLabelpopup = useCommonSelector((state) => state.commonSettings.ecoLabelpopup);

    if (!ecoLabelpopup) return null;

    const labels = [...ecoLabelpopup.labels, label];

    return (
        <Styles.WrapperPopup>
            <dxp-emission-label-popup
                data-labels={JSON.stringify(labels)}
                // For boolean properties they check if the property is added. They don't check the value that was passed
                data-standalone="true"
                // The web component can read the global settings of the page
                // It will use the property ecoLabelpopup from global settings to provide defaults when the values are not passed through the web component
                // The labels from the property ecoLabelpopup from global settings are automatically merged with the labels passed through the web component
                // To keep control in our component we disable this by adding data-ignore-dxp-settings
                data-ignore-dxp-settings="true"
            />
        </Styles.WrapperPopup>
    );
};

export default EmissionLabelPopup;
