import Debug from "../../../common-deprecated/Debug";
import { CommonSettingsType } from "../../../common-deprecated/settings/fetchCommonSettings";
import { COMPONENT_COMPARE_USC_PDF } from "../../../shared-logic/server/components";
import { PDF_CAR_SEPARATOR } from "../../../shared-logic/features/compare/utils/constants";
import { LoadingUsedCompareCarType } from "../../../common-deprecated/features/compare/utils/usedCarCompareTypes";
import { getUsedCarsFromLocalStorage } from "./localStorage";

export const getUscComparePdfLink = (commonSettings: CommonSettingsType): string => {
    const cars = getUsedCarsFromLocalStorage(commonSettings);
    const queryParams = cars.map(({ id }) => `${id}`).join("|");
    const uscEnvParam = commonSettings.query.uscEnv ? `&uscEnv=${commonSettings.query.uscEnv}` : "";

    return (
        `${commonSettings.resourcePath}/${commonSettings.country}/${commonSettings.language}/` +
        `${COMPONENT_COMPARE_USC_PDF}?compareData=${queryParams}` +
        `&brand=${commonSettings.brand}${uscEnvParam}`
    );
};

export const getUsedCarIdsFromUrl = (queryInput: string | null): LoadingUsedCompareCarType[] => {
    if (!queryInput) return [];
    // queryCarIds will be formatted as followed =>
    // carId1:carBrand1:carModel1|carId2:carBrand2:carModel2
    const cars = queryInput.split(PDF_CAR_SEPARATOR);
    const results: LoadingUsedCompareCarType[] = [];

    try {
        cars.forEach((car) => {
            const values = car.split(":");

            // values[1] and values[2] are optional
            if (!values[0]) {
                throw new Error("not all values are found in the Query parameter");
            }
            results.push({
                id: values[0],
                product: {
                    brand: { description: values[1] || "", code: "" },
                    model: values[2] || "",
                },
                loading: true,
            });
        });

        return results;
    } catch (e) {
        Debug.error((e as Error).message);
        return [];
    }
};
