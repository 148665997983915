import * as DOMUtils from "history/DOMUtils";
import { BreakPointType, getNumberFromPx } from "../../../../common-deprecated/themes/common";
import { CarDataType } from "../../../../common-deprecated/features/compare/utils/newCarCompareTypes";
import { CommonSettingsType } from "../../../../common-deprecated/settings/fetchCommonSettings";
import {
    ImageOptionsType,
    getCcisPath,
    getVisualForGradeImage,
} from "../../../../common-deprecated/settings/utils/ccisSettingUtils";
import { getModelToken, isLexus } from "../../../../common-deprecated/settings/utils/commonSettingUtils";

export const getMaxNumberOfCarsToCompare = (
    breakpoints: BreakPointType,
    compareV2Standalone: boolean = false,
): 2 | 3 | 4 => {
    if (DOMUtils.canUseDOM && window.innerWidth > getNumberFromPx(breakpoints.md)) {
        if (compareV2Standalone && window.innerWidth > getNumberFromPx(breakpoints.lg)) {
            return 4;
        }
        return 3;
    }
    return 2;
};

const hideElement = (element: HTMLElement, hide: boolean): void => {
    element.style.pointerEvents = hide ? "none" : "auto";
    element.style.visibility = hide ? "hidden" : "visible";
};
// Helper function to target and show/hide elements of the GFI site.

export const hideSiteElements = (hide: boolean, isSiteLexus: boolean): void => {
    let mainNav: HTMLElement | null;
    let secondaryNav: HTMLElement | null;
    let secondaryNavItem: HTMLElement | null;

    if (isSiteLexus) {
        mainNav = document.querySelector("#main-header");
        secondaryNav = null;
        secondaryNavItem = null;
    } else {
        mainNav = document.querySelector("#navigation");
        secondaryNav = document.querySelector("#nav-secondary-level");
        // GFI has set an element inside the nav specifically to visible so this line had to be added
        secondaryNavItem = document.querySelector("#doubleSecondLvlNav");
    }

    if (mainNav) hideElement(mainNav, hide);
    if (secondaryNav) hideElement(secondaryNav, hide);
    if (secondaryNavItem) hideElement(secondaryNavItem, hide);
};
export const getLexusCompareV2Image = (
    settings: CommonSettingsType,
    frame: number | string,
    modelId: string,
    subModelId: string,
    gradeId: string,
    bodyTypeId: string,
    colourCode: string,
    options: ImageOptionsType,
    token?: string,
): string => {
    const { bgColour = "FFFFFF", width = 1140, height = 426, imageQuality = 75, type = "jpg" } = options;
    const { country } = settings;

    const ccisPath = getCcisPath(settings, options);
    const modelToken = token || getModelToken(settings, modelId);
    const model = modelToken ? `product-token/${modelToken}` : `model/${modelId}`;
    const ids = `/${model}/grade/${gradeId}/body/${bodyTypeId}${subModelId ? `/submodel/${subModelId}` : ""}`;
    const background = bgColour ? `/background-colour/${bgColour}` : "";

    return (
        `${ccisPath}/${country}${ids}/width/${width}/height/${height}${background}` +
        `/image-quality/${imageQuality}/day-exterior-${frame}_${colourCode}.${type}`
    );
};
// Helper to get a single car image
const getCompareImage = (
    car: CarDataType | null,
    settings: CommonSettingsType,
    width: number,
    height: number,
): string => {
    const imageOptions: ImageOptionsType = {
        // These options should be the same as in ResultsContainer, this way the same cached image can be reused
        height,
        width,
        type: "png",
        bgColour: "",
        scaleMode: 2,
        padding: 10,
    };

    if (!car) return "";

    const colourCode = car.configuration?.defaultColourCombination
        ? car.configuration?.defaultColourCombination.exteriorColour.internalCode
        : car.colourCode;

    if (isLexus(settings)) {
        // TODO: check why getLexusCompareV2Image image service doesn't work when the submodelId is set
        return getLexusCompareV2Image(
            settings,
            9,
            car.modelId,
            "",
            car.gradeId,
            car.bodyTypeId,
            colourCode,
            imageOptions,
            car.modelToken,
        );
    }

    return getVisualForGradeImage(
        settings,
        car.modelId,
        car.gradeId,
        car.bodyTypeId,
        imageOptions,
        9,
        colourCode,
        car.modelToken,
    ).src;
};
// Helper to get car image srcSet

export const getCompareImages = (
    car: CarDataType | null,
    settings: CommonSettingsType,
): { default: string; set: string } => {
    return {
        default: getCompareImage(car, settings, 223, 128),
        set: `${getCompareImage(car, settings, 223, 128)} 1x, ${getCompareImage(
            car,
            settings,
            446,
            256,
        )} 2x, ${getCompareImage(car, settings, 669, 384)} 3x `,
    };
};
